<template>
  <div>
      <b-modal
        v-model="modalShow"
        modal-class="toggle-columns-dialog"
        ok-only
        ok-title="Ok"
        @ok="setColumns()"
        @hide="$emit('clearAction')"
      >
        <b-form-group 
          v-for="(item,index) in columns" 
          :key="index" 
          :label="item.label" 
          class="me-4"
          label-cols-md="4"
          content-cols-lg="8"
        >
          <b-form-checkbox v-model="item.enabled" id="h-active" switch />
        </b-form-group>
      </b-modal>
  </div>
</template>

<script>
export default {
    props: {
        columnsArray: {type: Array, default(){}},
        action: {type: Boolean, default: false},
    },
    watch: {
        action() {
            this.modalShow = this.action
        },
    },
    data() {
        return {
            modalShow: false,
            columns: this.columnsArray
        }
    },
    created() {
        this.$emit("init")
        this.setColumns()
    },
    methods: {
        setColumns() {
            const columnsExport = []
            this.columns.forEach(element => {
                if(element.enabled) {
                    columnsExport.push(element)
                }
            })
            this.$emit("clearAction")
            this.$emit("columnsChange", columnsExport)
        }
    }
}
</script>

<style>

</style>